import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight3: '#FFFFFF',
      neutralLight2: '#F5F6F6',
      neutralLight1: '#E4E8E9',
      neutralDark3: '#000000',
      neutralDark2: '#5D5D5D',
      neutralDark1: '#45403E',
      primaryDark: '#98A4A7',
      dangerLight: '#F23030',
    },
  },
  fontFamily: {
    label: "'itc-avant-garde-gothic-pro',sans-serif;",
    heading: "'itc-avant-garde-gothic-pro',sans-serif;",
    paragraph: "'acumin-pro', sans-serif",
  },
}

export type Styles = typeof styles

const titleStyle = css`
  color: ${theme.colors.variants.primaryDark};
  font-family: ${theme.fontFamily.heading};
  text-transform: uppercase;
`

export const styles = {
  label: css`
    color: ${theme.colors.variants.neutralLight3};
    font-family: ${theme.fontFamily.label};
    font-size: 0.75rem;
    letter-spacing: 0.1em;
    line-height: 1rem;
    font-weight: 500;
    text-transform: uppercase;
  `,
  title: {
    xl: css`
      ${titleStyle}
      font-size: 3.75rem;
      line-height: 3.75rem;
      font-weight: 300;

      @media (max-width: 1199px) {
        font-size: 2.625rem;
        line-height: 2.875rem;
      }
    `,
    large: css`
      ${titleStyle}
      font-size: 3rem;
      line-height: 3.25rem;
      font-weight: 300;

      @media (max-width: 1199px) {
        font-size: 2.25rem;
        line-height: 2.375rem;
      }
    `,
    medium: css`
      ${titleStyle}
      font-size: 2.25rem;
      line-height: 2.375rem;
      font-weight: 300;

      @media (max-width: 1199px) {
        font-size: 1.75rem;
        line-height: 2rem;
      }
    `,
    normal: css`
      ${titleStyle}
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 300;

      @media (max-width: 1199px) {
        font-size: 1.5rem;
        line-height: 1.875rem;
      }
    `,
    small: css`
      ${titleStyle}
      font-size: 1.125rem;
      line-height: 1.75rem;
      font-weight: 300;
      text-transform: none;

      @media (max-width: 1199px) {
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
    `,
  },
  subtitle: css`
    font-family: ${theme.fontFamily.label};
    color: ${theme.colors.variants.neutralDark1};
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.25rem;
  `,
  description: {
    dark: css`
      color: ${theme.colors.variants.neutralDark2};
      font-family: ${theme.fontFamily.paragraph};
      font-weight: 300;
      font-size: 1rem;
      line-height: 1.5rem;
    `,
    light: css`
      color: ${theme.colors.variants.neutralLight3};
      font-family: ${theme.fontFamily.paragraph};
      font-weight: 300;
      font-size: 1rem;
      line-height: 1.5rem;

      @media (max-width: 1199px) {
        font-size: 1rem;
        line-height: 1.625rem;
      }
    `,
  },
  img: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    will-change: contents;
  `,
}

export type Datepicker = typeof datepicker

export const datepicker = css`
  .react-datepicker {
    min-width: 20rem;
    font-family: ${theme.fontFamily.paragraph};
    background-color: ${theme.colors.variants.neutralLight3};
    box-shadow: ${rgba(theme.colors.variants.primaryDark, 0.1)} 0 0 1.25rem;
    color: ${theme.colors.variants.primaryDark};
  }

  .react-datepicker__portal {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${rgba(theme.colors.variants.neutralLight3, 0.8)};
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .react-datepicker__current-month {
    font-family: ${theme.fontFamily.paragraph};
    color: ${theme.colors.variants.primaryDark};
  }

  .react-datepicker__day-name {
    font-family: ${theme.fontFamily.paragraph};
  }

  .react-datepicker__day {
    border: 0.0625rem solid ${rgba(theme.colors.variants.primaryDark, 0.15)};
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--selected {
    border-color: ${theme.colors.variants.neutralLight1};
    background-color: ${theme.colors.variants.neutralLight1} !important;
    color: ${theme.colors.variants.neutralLight3} !important;
  }

  .react-datepicker__day:hover {
    background-color: ${theme.colors.variants.primaryDark};
    color: ${theme.colors.variants.neutralLight3};
  }

  .react-datepicker__day:focus {
    background-color: ${theme.colors.variants.primaryDark} !important;
    color: ${theme.colors.variants.neutralLight3} !important;
  }

  .react-datepicker__day--disabled {
    opacity: 0.3;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    input {
      background-color: transparent;
      background-image: url('/svg/calendar.svg');
      background-repeat: no-repeat;
      background-position: right center;
      font-family: ${theme.fontFamily.paragraph};
      font-size: 1rem;
      font-weight: 300;
      color: ${theme.colors.variants.primaryDark};
      line-height: 1.375rem;
      &.error {
        border-color: ${theme.colors.variants.dangerLight};
      }

      &::-webkit-input-placeholder {
        color: ${theme.colors.variants.primaryDark};
        opacity: 0.3;
      }
      &::-moz-placeholder {
        color: ${theme.colors.variants.primaryDark};
        opacity: 0.3;
      }
      &::-ms-input-placeholder {
        color: ${theme.colors.variants.primaryDark};
        opacity: 0.3;
      }
      &::placeholder {
        color: ${theme.colors.variants.primaryDark};
        opacity: 0.3;
      }
    }
  }
`

export type Numeric = typeof numeric

export const numeric = css`
  .react-numeric-input {
    display: flex;
    align-items: center;
    position: relative;
    input {
      width: 2.25rem;
      caret-color: transparent;
      color: ${theme.colors.variants.primaryDark};
      font-family: ${theme.fontFamily.paragraph};
      font-size: 1rem;
      font-weight: 700;
      order: 2;
      pointer-events: none;
      text-align: center;
    }
    b {
      width: 1.8125rem;
      height: 1.8125rem;
      background: ${theme.colors.variants.primaryDark};
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      text-align: center;
      transition: 0.1s ease-out;
      &:hover {
        background: ${theme.colors.variants.neutralDark3};
      }
      &:before,
      &:after {
        content: '';
        background: ${theme.colors.variants.neutralLight3};
        position: absolute;
        transition: 0.1s ease-out;
      }
      &:nth-of-type(1) {
        order: 2;
        &:before {
          width: 0.75rem;
          height: 0.0625rem;
          top: 0.875rem;
          left: 0.5rem;
        }
        &:after {
          width: 0.0625rem;
          height: 0.75rem;
          top: 0.5rem;
          left: 0.875rem;
        }
      }
      &:last-of-type {
        &:before {
          width: 0.75rem;
          height: 0.0625rem;
          top: 0.875rem;
          left: 0.5rem;
        }
      }
    }
  }
`

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
